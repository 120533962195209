import { useQuery, useMutation } from '@tanstack/react-query';
import UserHttpService from '../../../service/http/right/userHttpService';
import { GET_USERS_KEY, UPSERT_USERS_KEY, DELETE_USER_KEY, LOGOUT_USERS_KEY, LOGOUT_ALL_USERS_KEY, LINK_USER_GOOGLE_ACCOUNT, GET_USER_KEY, UNLINK_USER_EMAIL, RESET_EMPLOYEE_CREDENTIALS, UPDATE_EMPLOYEE_CREDENTIALS } from '../queryConstants';
import { queryErrorHandler, querySuccessHandler, queryDeleteSuccessHandler, queryNoReturnContentSuccessHandler } from '../queryStateHandlers';
import { IBaseUser } from '@zz2/zz2-ui';
import { IUserUpsert } from '../../../@types/model/right/user/userModels';

export const useGetUser = (userId : number, enabled ?: boolean) => {
    const query = useQuery<IBaseUser, Error>({
        queryKey: [GET_USER_KEY, userId],
        queryFn: () => UserHttpService.get(userId),
        onError: queryErrorHandler('Error loading User.'),
        enabled: enabled ?? true,
    });

    return query;
};

export const useGetUsers = () => {
    const query = useQuery<Array<IBaseUser>, Error>({
        queryKey: [GET_USERS_KEY],
        queryFn: ({ signal }) => UserHttpService.getList(signal),
        onError: queryErrorHandler('Error loading Users.'),
    });

    return query;
};

export const useHandleUsersUpsert = () => {
    const result = useMutation<Array<IBaseUser>, Error, Array<IUserUpsert>>({
        mutationKey: [UPSERT_USERS_KEY],
        mutationFn: (upsertData : Array<IUserUpsert>) => UserHttpService.upsert(upsertData),
        onError: queryErrorHandler('Error creating/updating User.'),
        onSuccess: querySuccessHandler([GET_USERS_KEY], 'User created/updated successfuly')
    });

    return result;
};

export const useHandleLinkGoogleAccount = () => {
    const result = useMutation<void, Error, string>({
        mutationKey: [LINK_USER_GOOGLE_ACCOUNT],
        mutationFn: (code : string) => UserHttpService.linkGoogleAccount(code),
        onError: queryErrorHandler('Error encountered while trying to link Google Account.'),
        onSuccess: queryNoReturnContentSuccessHandler('Google Account linked successfully.')
    });

    return result;
};

export const useHandleUserUnlinkEmail = () => {
    const result = useMutation<void, Error, Array<number>>({
        mutationKey: [UNLINK_USER_EMAIL],
        mutationFn: (userIds : Array<number>) => UserHttpService.unlinkEmail(userIds),
        onError: queryErrorHandler('Error encountered while trying to unlink user(s) email.'),
        onSuccess: queryNoReturnContentSuccessHandler('Unlinked user(s) email successfully.')
    });

    return result;
};

export const useHandleUpdateEmployeeCredentials = () => {
    const result = useMutation<void, Error>({
        mutationKey: [UPDATE_EMPLOYEE_CREDENTIALS],
        mutationFn: () => UserHttpService.updateEmployeeCredentials(),
        onError: queryErrorHandler('Error encountered while trying to update employee credentials.'),
        onSuccess: queryNoReturnContentSuccessHandler('Employee credentials updated successfully.')
    });

    return result;
};

export const useHandleResetEmployeeCredentials = () => {
    const result = useMutation<void, Error>({
        mutationKey: [RESET_EMPLOYEE_CREDENTIALS],
        mutationFn: () => UserHttpService.resetEmployeeCredentials(),
        onError: queryErrorHandler('Error encountered while trying to reset employee credentials.'),
        onSuccess: queryNoReturnContentSuccessHandler('Employee credentials reset successfully.')
    });

    return result;
};

export const useHandleLogoutUsers = () => {
    const result = useMutation<void, Error, Array<number>>({
        mutationKey: [LOGOUT_USERS_KEY],
        mutationFn: (userIds : Array<number>) => UserHttpService.logoutUsers(userIds),
        onError: queryErrorHandler('Error creating/updating User.'),
        onSuccess: queryNoReturnContentSuccessHandler('User(s) logged out successfully.')
    });

    return result;
};

export const useHandleLogoutAllUsers = () => {
    const result = useMutation<void, Error>({
        mutationKey: [LOGOUT_ALL_USERS_KEY],
        mutationFn: () => UserHttpService.logoutAllUsers(),
        onError: queryErrorHandler('Error logging out all users.'),
        onSuccess: queryNoReturnContentSuccessHandler('All users logged out successfully.')
    });

    return result;
};

export const useHandleDeleteUser = (userId ?: number) => {
    const result = useMutation<void, Error, number>({
        mutationKey: [DELETE_USER_KEY, userId],
        mutationFn: (userId : number) => UserHttpService.userDelete(userId),
        onError: queryErrorHandler('Error deactivating User.'),
        onSuccess: queryDeleteSuccessHandler([GET_USERS_KEY], 'User successfully deactivated.'),
    });

    return result;
};

